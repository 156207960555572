<template>
  <!-- Error page-->
  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <b-img
        :src="appLogoImage"
        alt="logo"
        center
        width="200px"
        class="mb-5"
      />
      <div class="w-100 text-center">
        <div v-if="!hasError">
          <h2 class="mb-1">
            Waiting... We will redirect you to the tenant information page
          </h2>
        </div>

        <div v-else>
          <div v-if="tenantNotFound">
            <h2 class="mb-1">
              Could not find tenant
            </h2>
            <b-button
              variant="primary"
              class="mb-2 btn-sm-block"
              :to="{path:'/'}"
            >
              Back to home
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BImg, BButton } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

import TenantService from '@/service/tenant.service'

export default {
  components: {
    BImg,
    BButton,
  },
  data() {
    return {
      tenantNotFound: false,
    }
  },
  setup() {
    const { appLogoImage } = $themeConfig.app

    return {
      appLogoImage,
    }
  },
  computed: {
    tenantId() {
      return this.$route.params.id
    },
    hasError() {
      return this.tenantNotFound
    },
  },
  mounted() {
    TenantService.findAsync(this.tenantId, { disableTenantFilter: true })
      .then(result => {
        this.redirectToTenant(result.id)
      })
      // eslint-disable-next-line no-unused-vars
      .catch(e => {
        this.tenantNotFound = true
      })
  },
  methods: {
    redirectToTenant(tenantId) {
      const routeParams = { id: tenantId }
      this.$router.replace({ name: 'host:manage:tenant-detail', params: routeParams })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
