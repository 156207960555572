// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  async getAllListAsync() {
    const pageSize = 1000
    let page = 0
    let totalCount = 0

    const result = []

    do {
      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get(`/core/tenant?MaxResultCount=${pageSize}&SkipCount=${page * pageSize}`)
      totalCount = response.data.totalCount
      result.push(...response.data.items)

      page += 1
    } while (result.length < totalCount)

    return result
  },

  async getAsync(id) {
    const response = await axios.get(`/core/tenant/${id}`)
    return response.data
  },

  /*
  * Find tenant by it´s id or old portal tenant id
  */
  async findAsync(id) {
    const response = await axios.get(`/core/tenant/find/${id}`)
    return response.data
  },

  async createAsync(data) {
    const response = await axios.post('/core/tenant', data)
    return response.data
  },
}
